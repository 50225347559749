import React from 'react'
import "./home-practice-areas.css"
import { motion } from "framer-motion"
const top = {
    hidden: {
        opacity: 0,
        x: -100
    },
    visible: {
        opacity: 1,
        x: 0
    }
}
class HomePracticeArea extends React.Component {
    render() {
        let content = (
            <div id="home-practice-area-container">
                <div id="home-practice-area-text-container">
                    <div id="home-practice-area-text">
                        <h1 className="heavy"
                            initial="hidden"
                            animate="visible"
                            variants={top}
                            transition={{ duration: 1 }}
                        >
                            PRACTICE AREAS
                        </h1>
                        <ul>
                            <li className="medium">Family <span>Law</span></li>
                            <li className="medium">Criminal <span>Law</span></li>
                            <li className="medium">Business <span>Law</span></li>
                            <li className="medium">Immigration <span>Law</span></li>
                        </ul>
                        <a href="/aboutus">
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            transition={{ duration: .5 }}
                            className="button-light regular">
                            click for <span>more</span>
                        </motion.button>
                        </a>
                    </div>
                </div>
                <div id="home-practice-area-image" style={this.props.image}>

                </div>
            </div>
        )
        if(window.innerWidth < 1130){
            content = (
                <div id="home-practice-area-container-mobile">
                <div id="home-practice-area-text-container-mobile">
                    <div id="home-practice-area-text-mobile">
                        <h1 className="heavy"
                            initial="hidden"
                            animate="visible"
                            variants={top}
                            transition={{ duration: 1 }}
                        >
                            PRACTICE AREAS
                        </h1>
                        <ul>
                            <li className="medium">Family <span>Law</span></li>
                            <li className="medium"> Business <span>Law</span></li>
                            <li className="medium">General <span>Litigation</span> </li>
                            <li className="medium">Immigration <span>Law</span></li>
                        </ul>
                        <a href="/aboutus">
                            <motion.button
                                whileHover={{ scale: 1.1 }}
                                transition={{ duration: .5 }}
                                className="button-light regular">
                                    click for <span>more</span>
                            </motion.button>
                        </a>
                    </div>
                </div>
            </div>
            )
        }
        return (
            <>
            {content}
            </>
        )
    }
}
export default HomePracticeArea;