import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import HeaderTwo from "../../../universal-components/header-two/header-two"
import AboutOurFirm from "../about-components/about-our-firm/about-our-firm"
import AboutPracticeAreas from "../about-components/about-practice-areas/about-practice-areas"
import "../../../universal-components/universal-css/universal-css.css"
import AboutBio from "../about-components/about-biography/about-biography"
import ABOUTUS from "../../../assets/about_us.jpg"
class About extends React.Component {
    render() {
        return (
            <Container fluid id="container-fluid-body">
                <Row>
                    <Col>
                        <HeaderTwo
                            titleOne="ABOUT US"
                            image="about-us"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AboutOurFirm/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AboutPracticeAreas/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AboutBio/>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default About