import React from "react"
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { Nav } from "react-bootstrap"
import "../nav-bar/nav-bar.css"
import "../universal-css/universal-css.css"
export const NavItems = (props) => {
  return (
    <motion.li
      className="nav-bar-item light" 
    >
      <Nav.Item className="nav-bar-item light">
        <Nav.Link as={Link} to={props.route} className="nav-bar-item light">{props.name}</Nav.Link>
      </Nav.Item>
    </motion.li>
  )
}