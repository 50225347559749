import React from "react"
import CopyRight from "../copy-right/copy-right";
import MapContainer from "../google-maps/google-maps";
import InformationBar from "../information-bar/information-bar";

import "./footer-main.css"
class Footer extends React.Component {
    render() {
        let content = (
            <div id="footer-main-container">
                <InformationBar />
                <div id="lol">
                <MapContainer />
                </div>
                <CopyRight />
            </div>
        )
        if(window.innerWidth < 1130){
            content = (
                <div id="footer-main-container">
                <InformationBar />
                <div id="lol-mobile">
                <MapContainer />
                </div>
                <CopyRight />
            </div>
            )
        }
        return (
            <>
            {content}
            </>
        )
    }
}
export default Footer;