import React from "react"
import { motion } from "framer-motion";
import { NavItems } from "../nav-item/nav-items";
import "../nav-bar/nav-bar.css"
export const Navigation = (props) => {
    return (
        <>
        <motion.ul
            id="nav-list"
            style={{flexDirection:props.test}}
        >
            {itemIds.map(i => (
                <NavItems i={i.key} key={i.key} name={i.name} route={i.route}/>
            ))}
        </motion.ul> 
        </>
    )
}

const itemIds = [
    {
        key: 0,
        name: "home",
        route: "/"
    },
    {
        key: 1,
        name: "about us",
        route: "/aboutus"
    },
    {
        key: 2,
        name: "contact us",
        route: "/contactus"
    },
    {
        key: 3,
        name: "privacy policy",
        route: "/privacypolicy"
    },
];