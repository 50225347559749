import React from "react"
import { Nav, Navbar } from "react-bootstrap"
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import "./nav-bar.css"
import ContactUs from '../../pages/contact/contact-main/contact-main';
import PrivacyPolicy from '../../pages/privacy/privacy-main/privacy-main';
import About from "../../pages/about/about-main/about-main"
import Home from "../../pages/home/home-main/home"
import LOGO from "../../assets/EG.png"
import { motion } from "framer-motion"
import LOGOINVERT from "../../assets/EGB.png"
import LOGOMOBILE from "../../assets/erick_logo_inverted.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Navigation } from "../navigation/navigation";
const mobileMenu = {
    open: {
        opacity: 1,
        x: 0,
        transition: {
            duration: .5,
            ease: "easeInOut"
        }
    },
    closed: {
        opacity: 0,
        x: "-100%",
        transition: {
            duration: .5,
            ease: "easeInOut"
        }
    },
    hoverEnter:{
        scale:1.1,
        transition:{
            ease: "easeInOut"
        }
    }
}
const mobileNav = {
    open: {
        opacity: 1,
        x: "15rem",
        transition: {
            duration: .1,
            delay: 0,
            ease: "easeInOut"
        }
    },
    closed: {
        opacity:0,
        x: 0,
        transition: {
            duration: .1,
            delay: 0,
            ease: "easeInOut"
        }
    }
}
const navlogo = {
    hidden: {
        opacity: 0,
        transition: { duration: 1.5, ease: "easeInOut" }
    },
    visible: {
        opacity: 1,
        transition: { duration: 1.5, delay: 3.15, ease: "easeInOut" }
    }
}
class NavBar extends React.Component {
    state = {
        navBackground: "transparent",
        logoInvert: false,
        isOpen: false
    }
    componentDidMount() {
        document.addEventListener("scroll", () => {
            const backgroundcolor = window.scrollY < 100 ? "transparent" : "fill";
            this.setState({ navBackground: backgroundcolor })
            const logoFill = window.scrollY < 100 ? false : true;
            this.setState({ logoInvert: logoFill });
        });
    }
    toggleNavHandler = () => {
        const neg = this.state.isOpen
        this.setState({ isOpen: !neg })
    }
    render() {
        const backgroundTest = {
            backgroundImage: LOGO
        }
        let desk = (
            <Navbar id="nav-bar" className={this.state.navBackground} variant="light" fixed expand="md">
                <Navbar.Brand as={Link} to='/'>
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={navlogo}
                    >
                        <img src={this.state.logoInvert ? LOGOINVERT : LOGO} id="logo-test" alt="Logo" />
                    </motion.div>
                </Navbar.Brand>
                <Nav className="justify-content-end" activeKey="/home">
                        <Navigation test={"row"}/>
                </Nav>
            </Navbar>
        )
        if (window.innerWidth < 1130) {
            desk = (
                <>
                <motion.div id="enter-icon-container"
                whileHover="hoverEnter"
                onClick={this.toggleNavHandler} 
                    animate={this.state.isOpen ? "closed": "open"}
                    variants={mobileMenu}
                >
                    <FontAwesomeIcon icon={faBars} id="enter-icon" />
                </motion.div>
                    <motion.nav id="nav-bar-mobile"
                    intial="closed"
                    animate={this.state.isOpen ? "open":"closed"}
                        variants={mobileNav}
                    >
                        <FontAwesomeIcon icon={faTimes} id="exit-icon" onClick={this.toggleNavHandler} />
                        <Navbar.Brand href="#home">
                            <motion.div
                                initial="hidden"
                                animate="visible"
                                variants={navlogo}
                            >
                                <img src={LOGOMOBILE} id="logo-test-mobile" alt="Logo" />
                            </motion.div>
                        </Navbar.Brand>
                        <Nav className="nav-content-mobile" activeKey="/home">
                            <Navigation test={"column"}/>
                        </Nav>
                    </motion.nav>
                </>
            )
        }
        return (
            <>
                {desk}
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/privacypolicy" component={PrivacyPolicy} />
                    <Route path="/aboutus" component={About} />
                    <Route path="/contactus" component={ContactUs} />
                </Switch>
            </>
        )
    }
}
export default NavBar;
/*
                               
                                <Nav.Item>
                                <Nav.Link className="nav-bar-item-mobile light" as={Link} to='/' onClick={this.toggleNavHandler}>home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="nav-bar-item-mobile light" as={Link} to='/about-us' onClick={this.toggleNavHandler}>about us</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="nav-bar-item-mobile light" as={Link} to='/contact-us' onClick={this.toggleNavHandler}>contact us</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="nav-bar-item-mobile light" as={Link} to='/privacy-policy' onClick={this.toggleNavHandler}>privacy policy</Nav.Link>
                            </Nav.Item>
                                */