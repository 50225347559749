import React from "react"
import "./about-practice-areas.css"
import { motion } from "framer-motion"
class AboutPracticeAreas extends React.Component {
    render() {
        let content = (
            <a name="about_practice_area">
            <div id="about-practice-areas-container">

                <div id="about-practice-area-text-container">
                    <h1 className="heavy">PRACTICE <span>AREAS</span></h1>
                    <div id="about-practice-list-container">
                        <ul>
                            <li>
                                Family <span>Law</span> 
                            </li>
                            <li>
                                Criminal <span>Law</span>
                            </li>
                            <li>
                                Business <span>Law</span>
                            </li>
                            <li>
                                General <span>Litigation</span> 
                            </li>
                            <li>
                                Immigration <span>Law</span>
                            </li>
                        </ul>
                    </div>
                    <motion.div
                    whileHover={{ scale: 1.03 }}
                    transition={{ duration: .5 }}
                    >
                    <a href="/contact_us/#contact_form" className="light" id="info">contact us for <span>more information</span></a>
                    </motion.div>
                </div>
            </div>
            </a>
        )
        if(window.innerWidth < 1130){
            content = (
                <a name="about_practice_area">
                <div id="about-practice-areas-container-mobile">
    
                    <div id="about-practice-area-text-container-mobile">
                        <h1 className="heavy">PRACTICE <span>AREAS</span></h1>
                        <div id="about-practice-list-container-mobile">
                            <ul>
                                <li>
                                    Family <span>Law</span> 
                                </li>
                                <li>
                                    Criminal <span>Law</span>
                                </li>
                                <li>
                                    Business <span>Law</span>
                                </li>
                                <li>
                                    General <span>Litigation</span> 
                                </li>
                                <li>
                                    Immigration <span>Law</span>
                                </li>
                            </ul>
                        </div>
                        <motion.div
                        whileHover={{ scale: 1.03 }}
                        transition={{ duration: .5 }}
                        >
                        <a href="/contact_us/#contact_form" className="light" id="info-mobile">contact us for <span>more information</span></a>
                        </motion.div>
                    </div>
                </div>
                </a>
            )
        }
        return (
            <>
                {content}
            </>
        )
    }
}
export default AboutPracticeAreas;