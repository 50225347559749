import React from "react"
import "./copy-right.css"
import "../../universal-css/universal-css.css"
class CopyRight extends React.Component {
    render() {
        let content = (
            <div id="copy-right-container">
                <h1 className="light">© 2020 The Law Office of Erick Garica</h1>
            </div>
        )
        if (window.innerWidth < 1130) {
            content = (
                <div id="copy-right-container-mobile">
                    <h1 className="light">© 2020 The Law Office of Erick Garica</h1>
                </div>
            )
        }
        return (
            <>
                {content}
            </>
        )
    }
}
export default CopyRight;