import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import HeaderTwo from "../../../universal-components/header-two/header-two"
import "../../../universal-components/universal-css/universal-css.css"
import ContactForms from "../contact-components/contact-form/contact-form"
import IMAG from "../../../assets/boyd-jumbo.jpg"
const contact = {
    backgroundImage:`url(${IMAG})`
}
class ContactUs extends React.Component {
    render() {
        return (
            <Container fluid id="container-fluid-body">
                <Row>
                    <Col>
                        <HeaderTwo
                            titleOne="CONTACT US"
                            image="contact-us"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ContactForms
                            image={contact}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default ContactUs;