import React from "react"
import "./about-biography.css"
import "../../../../universal-components/universal-css/universal-css.css"
import BG from "../../../../assets/tester.jpg"
import TITS from "../../../../assets/tits.jpg"

import MOBILE from "../../../../assets/mobile.jpg"
const backgroundImage = {
        backgroundImage:`url(${BG})`
}

const cow = {
    backgroundImage:`url(${TITS})`
}
const mobile = {
    backgroundImage:`url(${MOBILE})`
}
class AboutBio extends React.Component {
    render() {
        let content = (
            <div id="about-biography-container">
            <div id="about-biography-content-container">
                <div id="about-biography-image" style={cow}>

                </div>
                <div id="about-biography-text-container">
                    <div>
                        <h1 className="heavy">ERICK GARCIA</h1>
                        <p className="regular">principle <span>attorney</span></p>
                    </div>

                    <div>
                        <h1 className="heavy">EDUCATION</h1>
                        <p className="regular">California State University, <span>Dominguez Hills</span></p>
                        <p className="regular">University of La Verne <span>College of Law</span></p>
                    </div>

                    <div>
                        <h1 className="heavy">BAR ADMISSIONS</h1>
                        <p className="regular">State Bar of <span>California</span></p>
                        <p className="regular">U.S. District Court, <span>Central District of California</span></p>
                        <p className="regular">U.S. District Court, <span>Southern District of California</span></p>
                        <p className="regular">U.S. District Court, <span>Eastern District of California</span></p>
                        <p className="regular">Ninth Circuit, <span>U.S. Court of Appeals</span></p>
                    </div>

                    <div>
                        <h1 className="heavy">BIOGRAPHY</h1>
                        <p className="regular">Erick Garcia has been in the practice of law since the year 2011.
                        Upon his admission to the State Bar of California, he began practicing law in Whittier,
                        California, before opening the Law Office of Erick Garcia in Los Angeles. His experience
                        in the practice of law is not only focused in the State Courts of California, but also in various
                        Federal Courts across the country. The practice of law is not his only focus, but genuinely believes in
                        the concept that those to whom many opportunities are given, much is demanded; this principle has led him to actively serve on various non-profit
                        boards and be actively involved in the California State University System.</p>
                    </div>
                </div>
            </div>
        </div>
        )
        if(window.innerWidth < 1024){
            content = ( 
                <div id="about-biography-container-mobile">
                <div id="about-biography-content-container-mobile" style={mobile}>
                    <div id="about-biography-text-container-mobile">
                        <div>
                            <h1 className="heavy">ERICK GARCIA</h1>
                            <p className="regular">principal <span>attorney</span></p>
                        </div>

                        <div>
                            <h1 className="heavy">EDUCATION</h1>
                            <p className="regular">California State University, <br/> <span>Dominguez Hills</span></p>
                            <p className="regular">University of La Verne <br/> <span>College of Law</span></p>
                        </div>

                        <div>
                            <h1 className="heavy">BAR ADMISSIONS</h1>
                            <p className="regular">State Bar of <span>California</span></p>
                            <p className="regular">U.S. District Court, <br/> <span>Central District of California</span></p>
                            <p className="regular">U.S. District Court, <br/> <span>Southern District of California</span></p>
                            <p className="regular">U.S. District Court, <br/> <span>Eastern District of California</span></p>
                            <p className="regular">Ninth Circuit, <br/> <span>U.S. Court of Appeals</span></p>
                        </div>

                        <div>
                            <h1 className="heavy">BIOGRAPHY</h1>
                            <p className="regular">Erick Garcia has been in the practice of law since the year 2011.
                            Upon his admission to the State Bar of California, he began practicing law in Whittier,
                            California, before opening the Law Office of Erick Garcia in Los Angeles. His experience
                            in the practice of law is not only focused in the State Courts of California, but also in various
                            Federal Courts across the country. The practice of law is not his only focus, but genuinely believes in
                            the concept that those to whom many opportunities are given, much is demanded; this principle has led him to actively serve on various non-profit
                            boards and be actively involved in the California State University System.</p>
                        </div>
                    </div>
                </div>
            </div>
            )
        }
        return (
            <>
               {content}
            </>
        )
    }
}
export default AboutBio;