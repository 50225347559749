import React from "react"
import { Map,GoogleApiWrapper,Marker } from 'google-maps-react';
class MapContainers extends React.Component {
    render() {
        const mapStyles = {
          width: "100%",
          height: "55rem",
          marginBottom:"0"
        };
        return (
            <Map
                google={this.props.google}
                zoom={15}
                style={mapStyles}
                initialCenter={{ lat: 34.155478416564215, lng: -118.2555887865466 }}
            >
                <Marker position={{ lat: 34.155478416564215, lng: -118.2555887865466 }} />
            </Map>
        )
    } 
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDPSOA978l5SV29cm8DEL1v6g7ucoIdsX4'
})(MapContainers);