import React from "react"
import "./information-bar.css"
import "../../universal-css/universal-css.css"
import { motion } from "framer-motion"
import { Redirect ,  useHistory} from "react-router-dom";
function InformationBar(){
    let history = useHistory();

  function handleClick() {
    history.push("/contactus");
    history.go()
  }
  if(window.innerWidth < 1130){
      return (
        <div id="information-bar-container-mobile">
        <motion.div
        onClick={handleClick}
            whileHover={{ scale: 1.08 }}
            transition={{ ease: "easeOut", duration: .5 }}
            className="information-item-container-mobile">
            <h1 className="medium">CONTACT</h1>
            <p className="light">Phone: 213 - 814 - 0462</p>
            <p className="light">Fax: 213 - 814 - 2486</p>
            <p className="light">Email: info@garciaslaw.com</p>
        </motion.div>
        <motion.a
            whileHover={{ scale: 1.08 }}
            transition={{ ease: "easeOut", duration: .5 }}
            href="https://www.google.com/maps/place/611+N+Brand+Blvd,+Glendale,+CA+91203/@34.1552476,-118.2578204,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c0525840de1d:0x58a5130385c25bcd!8m2!3d34.1552476!4d-118.2556317"
            target="_blank">
            <div className="information-item-container-mobile">
                <h1 className="medium">ADDRESS</h1>
                <p className="light">611 N Brand Blvd Suite 1300</p>
                <p className="light">Glendale, CA 91203 </p>
            </div>
        </motion.a>
        <div
            className="information-item-container-mobile">
            <h1 className="medium">HOURS</h1>
            <p className="light">Monday to Thursday: 9am to 5pm  </p>
            <p className="light">Friday: 9am to 2pm </p>
            <p className="light">Saturday: By Appointment</p>
        </div>
    </div>
      )
  }
  else {
      return (
        <div id="information-bar-container">
        <motion.div
        onClick={handleClick}
            whileHover={{ scale: 1.08 }}
            transition={{ ease: "easeOut", duration: .5 }}
            className="information-item-container">
            <h1 className="medium">CONTACT</h1>
            <p className="light">Phone: 213 - 814 - 0462</p>
            <p className="light">Fax: 213 - 814 - 2486</p>
            <p className="light">Email: info@garciaslaw.com</p>
        </motion.div>
        <motion.a
            whileHover={{ scale: 1.08 }}
            transition={{ ease: "easeOut", duration: .5 }}
            href="https://www.google.com/maps/place/611+N+Brand+Blvd,+Glendale,+CA+91203/@34.1552476,-118.2578204,17z/data=!3m1!4b1!4m5!3m4!1s0x80c2c0525840de1d:0x58a5130385c25bcd!8m2!3d34.1552476!4d-118.2556317"
            target="_blank">
            <div className="information-item-container">
                <h1 className="medium">ADDRESS</h1>
                <p className="light"> 611 N Brand Blvd Suite 1300</p>
                <p className="light">Glendale, CA 91203 </p>
            </div>
        </motion.a>
        <div
            id="no-hover"
            className="information-item-container">
            <h1 className="medium">HOURS</h1>
            <p className="light">Monday to Thursday: 9am to 5pm  </p>
            <p className="light">Friday: 9am to 2pm </p>
            <p className="light">Saturday: By Appointment</p>
        </div>
    </div>
      )
  }
}
export default InformationBar;
/*
NO SCROLL DOWN
*/