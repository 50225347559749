import React from "react"
import "./privacy-content.css"
import "../../../universal-components/universal-css/universal-css.css"
class PrivacyContent extends React.Component {
    render() {
        let content = (
            <div id="privacy-content-container">
                <div id="privacy-content-text-container" className="light">
                    <p>
                        <span>The Law Office of Erick Garcia</span> (“us”, “we”, or “our”),
                    operates this website (Our “Services”) and has created
                    this Privacy Policy to inform you of Our collection, use
                    and sharing practices for information that we collect from you through Our Services.
	This privacy policy has been compiled to better serve those who are concerned
    with how their ‘Personally Identifiable Information’ (PII) is being used online.
    PII, as described in US privacy law and information security, is information that can
    be used on its own or with other information to identify, contact, or locate a single
    person, or to identify an individual in context. Please read our privacy policy carefully
     to get a clear understanding of how we collect, use, protect or otherwise handle your Personally
    Identifiable Information in accordance with our website.
                    </p>
                    <h1><span>What personal information do we collect from the people that visit our blog, website or app?</span></h1>
                    <p>
                        When registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address,
                        phone number or other details to help you with your experience.
                    </p>

                    <h1><span>When do we collect information?</span></h1>
                    <p>
                        We collect information from you when you register on our site, subscribe to a newsletter, respond to a survey, fill out a form or enter information on our site.
                    </p>

                    <h1><span>How do we use your information?</span></h1>
                    <p>
                        We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
                    </p>

                    <ul>
                        <li>
                            To personalize your experience and to allow us to deliver the type of content in which you are most interested.
                        </li>
                        <li>
                            To improve our website in order to better serve you.
                        </li>
                        <li>
                            To allow us to better service you in responding to your customer service requests.</li>
                        <li>
                            To administer a contest, promotion, survey or other site feature.</li>
                        <li>
                            To send periodic emails regarding your interest in our services.
                        </li>
                        <li>
                            To follow up with them after correspondence (live chat, email or phone inquiries)
                        </li>
                    </ul>

                    <h1><span>How do we protect your information?</span></h1>
                    <p>
                        Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.
                        We use regular Malware Scanning.
                        Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
                        We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.
                        All transactions are processed through a gateway provider and are not stored or processed on our servers.
                    </p>

                    <h1><span>Do we use ‘cookies’?</span></h1>
                    <p>
                        Yes. Cookies are small files that a site or its service provider transfers
                        to your computer’s hard drive through your Web browser (if you allow) that
                        enables the site’s or service provider’s systems to recognize your browser
                        and capture and remember certain information. For instance, we use cookies to
                        help us remember and process the items in your shopping cart. They are also used
                        to help us understand your preferences based on previous or current site activity,
                        which enables us to provide you with improved services. We also use cookies to help
                        us compile aggregate data about site traffic and site interaction so
                        that we can offer better site experiences and tools in the future.
                    </p>

                    <h1><span>We use cookies to:</span></h1>
                    <ul>
                        <li>
                            Understand and save user’s preferences for future visits.
                        </li>
                        <li>
                            Keep track of advertisements.
                        </li>
                        <li>
                            Compile aggregate data about site traffic and site interactions in order to offer better site
                            experiences and tools in the future. We may also use trusted third-party services
                            that track this information on our behalf.
                            You can choose to have your computer warn you each time a cookie is being sent, or you can choose to
                            turn off all cookies. You do this through your browser settings. Since each browser is a little different,
                            look at your browser’s Help Menu to learn the correct way to modify your cookies.
                        </li>
                    </ul>

                    <h1><span>If users disable cookies in their browser:</span></h1>
                    <p>
                        If you turn cookies off it will turn off some of the features of the site.
                    </p>

                    <h1><span>Third-party disclosure</span></h1>
                    <p>
                        We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property or safety.
                        However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                    </p>

                    <h1><span>Third-party links</span></h1>
                    <p>
                        Occasionally, at our discretion, we may include or offer third-party products
                        or services on our website. These third-party sites have separate and independent privacy
                        policies. We therefore have no responsibility or liability for the content and activities
                        of these linked sites. Nonetheless, we seek to protect the
                        integrity of our site and welcome any feedback about these sites.
                    </p>

                    <h1><span>Google</span></h1>
                    <p>
                        Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users. <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">Click Here for more</a>
                    </p>
                    <p>
                        We use Google AdSense Advertising on our website.
                        Google, as a third-party vendor, uses cookies to serve ads on our site. Google’s use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy.
                    </p>

                    <h1><span>We have implemented the following:</span></h1>
                    <ul>
                        <li>
                            Remarketing with Google AdSense
                        </li>
                        <li>
                            Demographics and Interests Reporting
                        </li>
                        <li>
                            DoubleClick Platform Integration
                        </li>
                    </ul>
                    <p>
                        We, along with third-party vendors such as Google use first-party
                        cookies (such as the Google Analytics cookies) and third-party cookies
                        (such as the DoubleClick cookie) or other third-party identifiers together to
                        compile data regarding user interactions with ad
                        impressions and other ad service functions as they relate to our website.
                    </p>

                    <h1><span>Opting out:</span></h1>
                    <p>
                        Users can set preferences for how Google advertises to you using the Google Ad Settings page.
                        Alternatively, you can opt out by visiting the Network Advertising
                        Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on.
                    </p>

                    <h1><span>California Online Privacy Protection Act</span></h1>
                    <p>
                        CalOPPA is the first state law in the nation to require commercial websites and
                        online services to post a privacy policy. The law’s reach stretches well beyond
                        California to require any person or company in the United States (and conceivably the world)
                        that operates websites collecting Personally Identifiable Information from California consumers
                        to post a conspicuous privacy policy on its website stating exactly the information being collected
                    and those individuals or companies with whom it is being shared. <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">Click here for more.</a>
                    </p>

                    <h1><span>According to CalOPPA, we agree to the following:</span></h1>
                    <p>
                        Users can visit our site anonymously.
                        Once this privacy policy is created, we will add a link to it on our home page or as a
                        minimum, on the first significant page after entering our website.
                        Our Privacy Policy link includes the word ‘Privacy’ and can easily be found on the page specified above.
                    </p>
                    <p>You will be notified of any Privacy Policy changes:</p>
                    <ul>
                        <li>
                            On our Privacy Policy Page
                        </li>
                    </ul>
                    <p>Can change your personal information:</p>
                    <ul>
                        <li>
                            By emailing us
                        </li>
                    </ul>

                    <h1><span>How does our site handle Do Not Track signals?</span></h1>
                    <p>
                        We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
                    </p>

                    <h1><span>Does our site allow third-party behavioral tracking?</span></h1>
                    <p>
                        It’s also important to note that we allow third-party behavioral tracking
                    </p>

                    <h1><span>COPPA (Children Online Privacy Protection Act)</span></h1>
                    <p>
                        When it comes to the collection of personal information from children
                        under the age of 13 years old, the Children’s Online Privacy Protection Act
                        (COPPA) puts parents in control. 	The Federal Trade Commission, United States’
                        consumer protection agency, enforces the COPPA Rule, which spells out what operators of
                        websites and online services must do to protect children’s privacy and safety online.
                        We do not specifically market to children under the age of 13 years old.
                    </p>

                    <h1><span>Fair Information Practices</span></h1>
                    <p>
                        The Fair Information Practices Principles form the backbone of privacy law in
                        the United States and the concepts they include have played a significant role
                        in the development of data protection laws around the globe. Understanding the Fair
                        Information Practice Principles and how they should be implemented is
                        critical to comply with the various privacy laws that protect personal information.
                    </p>

                    <h1><span>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</span></h1>
                    <p>
                        We will notify you via email
                    </p>
                    <ul>
                        <li>
                            Within 7 business days
                        </li>
                    </ul>
                    <p>
                        We will notify the users via in-site notification
                    </p>
                    <ul>
                        <li>
                            Within 7 business days
                        </li>
                    </ul>
                    <p>
                    We also agree to the Individual Redress Principle which requires that individuals 
                    have the right to legally pursue enforceable rights against data collectors and processors 
                    who fail to adhere to the law. This principle requires not only that individuals have enforceable 
                    rights against data users, but also that individuals have recourse to courts or 
                    government agencies to investigate and/or prosecute non-compliance by data processors.
                    </p>
                </div>
            </div>
        )
        if(window.innerWidth < 1130){
            content = (
                <div id="privacy-content-container-mobile">
                <div id="privacy-content-text-container-mobile" className="light">
                    <p>
                        <span>The Law Office of Erick Garcia</span> (“us”, “we”, or “our”),
                    operates this website (Our “Services”) and has created
                    this Privacy Policy to inform you of Our collection, use
                    and sharing practices for information that we collect from you through Our Services.
	This privacy policy has been compiled to better serve those who are concerned
    with how their ‘Personally Identifiable Information’ (PII) is being used online.
    PII, as described in US privacy law and information security, is information that can
    be used on its own or with other information to identify, contact, or locate a single
    person, or to identify an individual in context. Please read our privacy policy carefully
     to get a clear understanding of how we collect, use, protect or otherwise handle your Personally
    Identifiable Information in accordance with our website.
                    </p>
                    <h1><span>What personal information do we collect from the people that visit our blog, website or app?</span></h1>
                    <p>
                        When registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address,
                        phone number or other details to help you with your experience.
                    </p>

                    <h1><span>When do we collect information?</span></h1>
                    <p>
                        We collect information from you when you register on our site, subscribe to a newsletter, respond to a survey, fill out a form or enter information on our site.
                    </p>

                    <h1><span>How do we use your information?</span></h1>
                    <p>
                        We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:
                    </p>

                    <ul>
                        <li>
                            To personalize your experience and to allow us to deliver the type of content in which you are most interested.
                        </li>
                        <li>
                            To improve our website in order to better serve you.
                        </li>
                        <li>
                            To allow us to better service you in responding to your customer service requests.</li>
                        <li>
                            To administer a contest, promotion, survey or other site feature.</li>
                        <li>
                            To send periodic emails regarding your interest in our services.
                        </li>
                        <li>
                            To follow up with them after correspondence (live chat, email or phone inquiries)
                        </li>
                    </ul>

                    <h1><span>How do we protect your information?</span></h1>
                    <p>
                        Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.
                        We use regular Malware Scanning.
                        Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.
                        We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.
                        All transactions are processed through a gateway provider and are not stored or processed on our servers.
                    </p>

                    <h1><span>Do we use ‘cookies’?</span></h1>
                    <p>
                        Yes. Cookies are small files that a site or its service provider transfers
                        to your computer’s hard drive through your Web browser (if you allow) that
                        enables the site’s or service provider’s systems to recognize your browser
                        and capture and remember certain information. For instance, we use cookies to
                        help us remember and process the items in your shopping cart. They are also used
                        to help us understand your preferences based on previous or current site activity,
                        which enables us to provide you with improved services. We also use cookies to help
                        us compile aggregate data about site traffic and site interaction so
                        that we can offer better site experiences and tools in the future.
                    </p>

                    <h1><span>We use cookies to:</span></h1>
                    <ul>
                        <li>
                            Understand and save user’s preferences for future visits.
                        </li>
                        <li>
                            Keep track of advertisements.
                        </li>
                        <li>
                            Compile aggregate data about site traffic and site interactions in order to offer better site
                            experiences and tools in the future. We may also use trusted third-party services
                            that track this information on our behalf.
                            You can choose to have your computer warn you each time a cookie is being sent, or you can choose to
                            turn off all cookies. You do this through your browser settings. Since each browser is a little different,
                            look at your browser’s Help Menu to learn the correct way to modify your cookies.
                        </li>
                    </ul>

                    <h1><span>If users disable cookies in their browser:</span></h1>
                    <p>
                        If you turn cookies off it will turn off some of the features of the site.
                    </p>

                    <h1><span>Third-party disclosure</span></h1>
                    <p>
                        We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property or safety.
                        However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.
                    </p>

                    <h1><span>Third-party links</span></h1>
                    <p>
                        Occasionally, at our discretion, we may include or offer third-party products
                        or services on our website. These third-party sites have separate and independent privacy
                        policies. We therefore have no responsibility or liability for the content and activities
                        of these linked sites. Nonetheless, we seek to protect the
                        integrity of our site and welcome any feedback about these sites.
                    </p>

                    <h1><span>Google</span></h1>
                    <p>
                        Google’s advertising requirements can be summed up by Google’s Advertising Principles. They are put in place to provide a positive experience for users. <a href="https://support.google.com/adwordspolicy/answer/1316548?hl=en">Click Here for more</a>
                    </p>
                    <p>
                        We use Google AdSense Advertising on our website.
                        Google, as a third-party vendor, uses cookies to serve ads on our site. Google’s use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy.
                    </p>

                    <h1><span>We have implemented the following:</span></h1>
                    <ul>
                        <li>
                            Remarketing with Google AdSense
                        </li>
                        <li>
                            Demographics and Interests Reporting
                        </li>
                        <li>
                            DoubleClick Platform Integration
                        </li>
                    </ul>
                    <p>
                        We, along with third-party vendors such as Google use first-party
                        cookies (such as the Google Analytics cookies) and third-party cookies
                        (such as the DoubleClick cookie) or other third-party identifiers together to
                        compile data regarding user interactions with ad
                        impressions and other ad service functions as they relate to our website.
                    </p>

                    <h1><span>Opting out:</span></h1>
                    <p>
                        Users can set preferences for how Google advertises to you using the Google Ad Settings page.
                        Alternatively, you can opt out by visiting the Network Advertising
                        Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on.
                    </p>

                    <h1><span>California Online Privacy Protection Act</span></h1>
                    <p>
                        CalOPPA is the first state law in the nation to require commercial websites and
                        online services to post a privacy policy. The law’s reach stretches well beyond
                        California to require any person or company in the United States (and conceivably the world)
                        that operates websites collecting Personally Identifiable Information from California consumers
                        to post a conspicuous privacy policy on its website stating exactly the information being collected
                    and those individuals or companies with whom it is being shared. <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">Click here for more.</a>
                    </p>

                    <h1><span>According to CalOPPA, we agree to the following:</span></h1>
                    <p>
                        Users can visit our site anonymously.
                        Once this privacy policy is created, we will add a link to it on our home page or as a
                        minimum, on the first significant page after entering our website.
                        Our Privacy Policy link includes the word ‘Privacy’ and can easily be found on the page specified above.
                    </p>
                    <p>You will be notified of any Privacy Policy changes:</p>
                    <ul>
                        <li>
                            On our Privacy Policy Page
                        </li>
                    </ul>
                    <p>Can change your personal information:</p>
                    <ul>
                        <li>
                            By emailing us
                        </li>
                    </ul>

                    <h1><span>How does our site handle Do Not Track signals?</span></h1>
                    <p>
                        We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.
                    </p>

                    <h1><span>Does our site allow third-party behavioral tracking?</span></h1>
                    <p>
                        It’s also important to note that we allow third-party behavioral tracking
                    </p>

                    <h1><span>COPPA (Children Online Privacy Protection Act)</span></h1>
                    <p>
                        When it comes to the collection of personal information from children
                        under the age of 13 years old, the Children’s Online Privacy Protection Act
                        (COPPA) puts parents in control. 	The Federal Trade Commission, United States’
                        consumer protection agency, enforces the COPPA Rule, which spells out what operators of
                        websites and online services must do to protect children’s privacy and safety online.
                        We do not specifically market to children under the age of 13 years old.
                    </p>

                    <h1><span>Fair Information Practices</span></h1>
                    <p>
                        The Fair Information Practices Principles form the backbone of privacy law in
                        the United States and the concepts they include have played a significant role
                        in the development of data protection laws around the globe. Understanding the Fair
                        Information Practice Principles and how they should be implemented is
                        critical to comply with the various privacy laws that protect personal information.
                    </p>

                    <h1><span>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</span></h1>
                    <p>
                        We will notify you via email
                    </p>
                    <ul>
                        <li>
                            Within 7 business days
                        </li>
                    </ul>
                    <p>
                        We will notify the users via in-site notification
                    </p>
                    <ul>
                        <li>
                            Within 7 business days
                        </li>
                    </ul>
                    <p>
                    We also agree to the Individual Redress Principle which requires that individuals 
                    have the right to legally pursue enforceable rights against data collectors and processors 
                    who fail to adhere to the law. This principle requires not only that individuals have enforceable 
                    rights against data users, but also that individuals have recourse to courts or 
                    government agencies to investigate and/or prosecute non-compliance by data processors.
                    </p>
                </div>
            </div>
            )
        }
        return (
            <>
            {content}
            </>
        )
    }
}
export default PrivacyContent;