import React from "react"
import "./about-our-firm.css"
import "../../../../universal-components/universal-css/universal-css.css"
import SHAKE from "../../../../assets/handshake.jpg"

const backgroundImage = {
    backgroundImage:`url(${SHAKE})`
}
class AboutOurFirm extends React.Component {
    render() {
        let content = (
            <div id="about-our-firm-container">
                <div id="about-our-firm-image" style={backgroundImage}>

                </div>
                <div id="about-our-firm-text-container">
                    <h1 className="heavy">ABOUT OUR <span>OFFICE</span></h1>
                    <p className="regular">
                    Our office strives to provide personalized service and attention 
                        to the needs of each client, this attention to detail leads us 
                        to obtain best results for our clients. We are excited to provide representation 
                        nationwide at the federal level and local representation across California. 
                    </p>
                </div>
            </div>
        )
        if (window.innerWidth < 1130) {
            content = (
                <div id="about-our-firm-container-mobile">
                    <div id="about-our-firm-text-container-mobile">
                        <h1 className="heavy">ABOUT OUR <span>OFFICE</span></h1>
                        <p className="regular">
                        Our office strives to provide personalized service and attention 
                        to the needs of each client, this attention to detail leads us 
                        to obtain best results for our clients. We are excited to provide representation 
                        nationwide at the federal level and local representation across California. 
                        </p>
                    </div>
                </div>
            )
        }

        return (
            <>
                {content}
            </>
        )
    }
}
export default AboutOurFirm;