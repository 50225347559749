import React from "react"
import { Container, Row, Col} from "react-bootstrap";
import HeaderTwo from "../../../universal-components/header-two/header-two";
import "../../../universal-components/universal-css/universal-css.css"
import PrivacyContent from "../privacy-components/privacy-content";
class PrivacyPolicy extends React.Component{
    render(){
        return (
            <Container fluid id="container-fluid-body">
                <Row>
                    <Col>
                        <HeaderTwo
                        titleOne="Privacy Policy"

                        image="privacy-policy"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <PrivacyContent/>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default PrivacyPolicy;