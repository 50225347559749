import React, { useLayoutEffect, useState } from 'react';

import './App.css';
import NavBar from "./universal-components/nav-bar/nav-bar"
import Footer from './universal-components/footer/footer-main/footer-main';
import ContactUs from './pages/contact/contact-main/contact-main';
import PrivacyPolicy from './pages/privacy/privacy-main/privacy-main';
import About from "../src/pages/about/about-main/about-main"
import Home from "../src/pages/home/home-main/home"

class App extends React.Component {
  state = {
    windowHeight: null,
    windowWidth: null
  }
  handleResize = () => this.setState({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth
  });
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  render(){
    return (
      <>
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
          integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
          crossOrigin="true"
        />
        <link crossOrigin="true" href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700&display=swap" rel="stylesheet" />
        <script crossOrigin="true"
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCUJ1ULr0UgyWn9w8daVVdfNdeH2LlvTF0&callback=initMap">
        </script>
        <NavBar />
        <Footer />
      </>
    );
  }
}

export default App;
/*
  NOTES HERE PLZ 

  on home page: center teh coverlink so that is not too far to the lef





*/
