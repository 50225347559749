import React from "react"
import "./header-one.css"
import "../universal-css/universal-css.css"
import FULLLOGO from "../../assets/erick_logo.png"
import { motion } from "framer-motion"
const top = {
    hidden: {
        opacity: 0,
        y: 0
    },
    visible: {
        opacity: 1,
        y: -150
    }
}
const link = {
    hidden: {
        opacity: 0,
        y: 100,
        transition: { duration: 1.5, ease: "easeInOut" }
    },
    visible: {
        opacity: 1,
        y: -150,
        transition: { duration: 1.5, delay: 4.5, ease: "easeInOut" }
    },
    onTheHover: {
        scale: 1.1,
        transition: { duration: .5 }
    }
}
const image = {
    hidden: {
        opacity: 1,
        y: 200,
        transition: { duration: 1, ease: "easeInOut" }
    },
    visible: {
        opacity: 0,
        y: -150,
        transition: { duration: 1.5, delay:2.5,ease: "easeInOut" }
    }
}
const headerone = {
    backgroundImage:null
}
/*MOBILE*/
const topmobile = {
    hidden: {
        opacity: 0,
        y: 200
    },
    visible: {
        opacity: 1,
        y:0
    }
}
const linkmobile = {
    hidden: {
        opacity: 0,
        y: 100,
        transition: { duration: 1.5, ease: "easeInOut" }
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { duration: 1.5, delay: 4.5, ease: "easeInOut" }
    },
    onTheHover: {
        scale: 1.1,
        transition: { duration: .5 }
    }
}
const imagemobile = {
    hidden: {
        opacity: 1,
        y: 0,
        transition: { duration: 1, ease: "easeInOut" }
    },
    visible: {
        opacity: 0,
        y: -300,
        transition: { duration: 1.5, delay:2.5,ease: "easeInOut" }
    }
}
class HeaderOne extends React.Component {
    render() {
        let headerone = (
            <div id="header-one-container">
                    <div id="header-one-text-container">
                        <div id="header-one-text">
                        <motion.img src={FULLLOGO} 
                        initial="hidden"
                        animate="visible"
                        variants={image}
                        description="logo"/>
                            <motion.div
                                id="header-one-title"
                                initial="hidden"
                                animate="visible"
                                variants={top}
                                transition={{ duration: 1.5, delay: 3, ease: "easeInOut" }}
                            >
                                <h1 className="heavy">{this.props.titleOne}</h1>
                                <h1 className="heavy">{this.props.titleTwo}</h1>
                                <h1 className="heavy">{this.props.titleThree}</h1>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                animate="visible"
                                variants={link}
                            >
                                <motion.p
                                animate={{
                                        scale:[1,1.06,1]
                                }}
                                transition={{
                                    duration: 2,
                                    delay: 5,
                                    ease: "easeInOut",
                                    times: [0, 0.5, 1],
                                    loop: Infinity
                                  }}
                                className="button-light regular"
                                style={{ marginTop: "0" }}
                                >
                                    scroll down for <span>more</span>
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                    <div id="header-one-image" style={this.props.image}>

                    </div>
                </div>
        )
        if(window.innerWidth < 800){
            headerone = (
                <div id="header-one-container-mobile">
                    <div id="header-one-text-container-mobile">
                        <div id="header-one-text-mobile">
                        <motion.img src={FULLLOGO} 
                        initial="hidden"
                        animate="visible"
                        variants={imagemobile}
                        description="logo"/>
                            <motion.div
                                id="header-one-title-mobile"
                                initial="hidden"
                                animate="visible"
                                variants={topmobile}
                                transition={{ duration: 1.5, delay: 3, ease: "easeInOut" }}
                            >
                                <h1 className="heavy">{this.props.titleOne}</h1>
                                <h1 className="heavy">{this.props.titleTwo}</h1>
                                <h1 className="heavy">{this.props.titleThree}</h1>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                animate="visible"
                                variants={linkmobile}
                            >
                                <motion.p
                                animate={{
                                        scale:[1,1.06,1]
                                }}
                                transition={{
                                    duration: 2,
                                    delay: 5,
                                    ease: "easeInOut",
                                    times: [0, 0.5, 1],
                                    loop: Infinity
                                  }}
                                className="button-light regular"
                                style={{ marginTop: "0" }}
                                >
                                    scroll down for <span>more</span>
                                </motion.p>
                            </motion.div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <>
                {headerone}
            </>
        )
    }
}
export default HeaderOne;