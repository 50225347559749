import React from "react"
import emailjs from "emailjs-com"
import "./contact-form.css"
import "../../../../universal-components/universal-css/universal-css.css"
import {Form} from "react-bootstrap"
import{ init } from 'emailjs-com';
import { motion } from "framer-motion"

init("user_HAiLB6dopkJKyqqMmK9RT");
class ContactForms extends React.Component {
    constructor() {
        super()
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    restartInput = () =>{
        console.log("restart")
        document.getElementById("email").value = ''
        document.getElementById("message").value = ''
        document.getElementById("name").value = ''
        document.getElementById("phone").value = ''
    }
    handleSubmit(e) {
        e.preventDefault()
        const templateID = 'template_rwbl3zs'
        const serviceID = 'service_vv3krgu'
        emailjs.send(serviceID,templateID, {message: this.state.message, from_name: this.state.name, from_email: this.state.email, from_number: this.state.phone},"user_v5T7LRY1DzkUHvvkfOBlw")
        .then((result) => {
            console.log(result.text);
            this.restartInput()
            alert("Email Sent")
        }, (error) => {
            console.log(error.text);

            alert("En Error has occured please contact our office through phone")
        });
    }

    render() {
        let content = (
            <a name="contact_form">
                 <div id="contact-forms-container">
                <div id="contact-forms-image" style={this.props.image}>

                </div>
                <div id="contact-forms-content">
                    <h1 className="heavy">GET IN <span>TOUCH</span></h1>
                    <Form id="contact-forms" onSubmit={this.handleSubmit}>
                        <input type="text" name="name" placeholder="name" id="name" required onChange={this.handleChange}/>
                        <input type="email" name="email" placeholder="email" id="email" required onChange={this.handleChange}/>
                        <input type="text" name="phone" placeholder="phone" id="phone" required onChange={this.handleChange}/>
                        <textarea type="text" name="message" placeholder="message" id="message" required onChange={this.handleChange}></textarea>
                        <motion.input 
                            whileHover={{ scale: 1.1 }}
                            transition={{ duration: 0.5 }}
                            id="contact-form-submit"type="submit"  value="submit"/>
                    </Form>
                </div>
            </div>
            </a>
        )
        if(window.innerWidth < 1130){
            content = (
                <a name="contact_form">
                 <div id="contact-forms-container-mobile">
                <div id="contact-forms-content-mobile">
                    <h1 className="heavy">GET IN <span>TOUCH</span></h1>
                    <Form id="contact-forms-mobile" onSubmit={this.handleSubmit}>
                        <input type="text" name="name" placeholder="name" id="name" required onChange={this.handleChange}/>
                        <input type="email" name="email" placeholder="email" id="email" required onChange={this.handleChange}/>
                        <input type="text" name="phone" placeholder="phone" id="phone" required onChange={this.handleChange}/>
                        <textarea type="text" name="message" placeholder="message" id="message" required onChange={this.handleChange}></textarea>
                        <motion.input 
                            whileHover={{ scale: 1.1 }}
                            transition={{ duration: 0.5 }}
                            id="contact-form-submit" type="submit"  value="submit"/>
                    </Form>
                </div>
            </div>
            </a>
            )
        }
        return (
            <>
            {content}
            </>
        )
    }
}
export default ContactForms;
/**/