import React from "react"
import { Container, Row, Col } from "react-bootstrap";
import HeaderOne from "../../../universal-components/header-one/header-one";
import "./home.css"
import "../../../universal-components/universal-css/universal-css.css"
import HomePracticeArea from "../home-practice-areas/home-practice-areas";
import HOMEIMAGE from "../../../assets/home_image.jpg"
import LAW from "../../../assets/law.jpg"
const headerone = {
    backgroundImage:`url(${HOMEIMAGE})`
}
const lawimage = {
    backgroundImage:`url(${LAW})`
}
class Home extends React.Component {
    render() {
        return (
            <Container fluid id="container-fluid-body">
                <Row>
                    <Col>
                        <HeaderOne
                            titleOne="EXPERIENCE"
                            titleTwo="TRUST"
                            titleThree="RESULTS"
                            image={headerone}
                            home={true}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <HomePracticeArea 
                        image={lawimage}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default Home;